<template>
    <div class="d-flex content-center min-vh-100">
        <BContainer>
            <BRow class="justify-content-center">
                <BCol md="8">
                    <BCard class="text-left" footer-tag="footer" footer-bg-variant="light">
                        <div slot="header">
                            <strong>견적서 추가 정보</strong>
                            <div class="card-header-actions">
                                <small class="text-muted">HowCost에 가입하셨던 기본정보가 자동 설정됩니다.</small>
                            </div>
                        </div>

                        <BForm @submit="onSubmit">

                            <div>
                                <label for="example-datepicker">자재 요청일자</label>
                                <b-form-datepicker 
                                    id="example-datepicker" 
                                    v-model="dateValue"
                                    :state="dateValue.length>=1"
                                    class="mb-2" 
                                    :min="min" 
                                    aria-required="true"
                                    placeholder="클릭 후 원하는 날짜를 선택해주세요" 
                                    required></b-form-datepicker>
                                <p class="small ml-1" style="color: #768192;">자재가 필요한 일자를 선택해주세요</p>
                                <!-- <p>Value: '{{ value }}'</p> -->
                            </div>

                            <BFormGroup class="mt-3"
                                        label="회사명 (업체명)"
                                        label-for="userCompanyInput"
                                        description="회원님이 소속하신 회사명을 입력해주세요">
                                <BInputGroup id="userCompanyInput">
                                    <BInputGroupPrepend is-text><BIconBagDashFill/></BInputGroupPrepend>
                                    <BFormInput v-model="companyName"
                                                :state="companyName.length>=2"
                                                type="text"
                                                id="companyName"
                                                placeholder="회원님이 소속하신 회사명을 입력해주세요"
                                                required/>
                                </BInputGroup>
                            </BFormGroup>

                            <BFormGroup class="mt-3"
                                        label="담당자 이름"
                                        label-for="NameInput"
                                        description="공급자가 알아볼 수 있는 견적을 요청한 담당자분의 이름을 입력하세요">
                                <BInputGroup id="NameInput">
                                    <BInputGroupPrepend is-text><BIconPersonBadgeFill/></BInputGroupPrepend>
                                    <BFormInput v-model="userName"
                                                :state="userName.length>=2"
                                                type="text"
                                                placeholder="공급자가 알아볼 수 있는 담당자의 이름을 입력하세요"
                                                required/>
                                </BInputGroup>
                            </BFormGroup>

                            <BFormGroup class="mt-3"
                                        label="연락처"
                                        label-for="numberInput"
                                        description="하이픈(-)을 제외한 담당자의 연락처를 입력해주세요">
                                <BInputGroup id="numberInput">
                                    <BInputGroupPrepend is-text><BIconPhoneFill/></BInputGroupPrepend>
                                    <BFormInput v-model="userPhone"
                                                @keyup="getPhoneMask(userPhone)"
                                                :state="userPhone.length>=12"
                                                type="text"
                                                placeholder="담당자의 연락처를 입력해주세요"
                                                required/>
                                </BInputGroup>
                            </BFormGroup>

                            <BFormGroup class="mt-3"
                                        label="이메일 주소"
                                        label-for="emailInput"
                                        description="공급자와 연락할 수 있는 담당자 이메일을 입력해주세요">
                                <BInputGroup id="emailInput">
                                    <BInputGroupPrepend is-text><BIconAt/></BInputGroupPrepend>
                                    <BFormInput v-model="userEmail"
                                                :state="validEmailState"
                                                type="email"
                                                placeholder="이메일 주소를 입력하세요"
                                                required/>
                                </BInputGroup>
                            </BFormGroup>

                            <BFormGroup class="mt-3"
                                        label="공사명"
                                        label-for="projectInput"
                                        description="자재가 들어갈 현장의 공사명을 입력해주세요">
                                <BInputGroup id="projectInput">
                                    <BInputGroupPrepend is-text><BIconXDiamondFill/></BInputGroupPrepend>
                                    <BFormInput v-model="projectName"
                                                type="text"
                                                :state="projectName.length>=2"
                                                placeholder="예) 하우코스트 신사옥 신축공사"
                                                required/>
                                </BInputGroup>
                            </BFormGroup>

                            <BFormGroup class="mt-3"
                                        label="견적조건 (선택)"
                                        label-for="conditionInput"
                                        description="(선택) 공급자에게 요청할 내용을 입력해주세요">
                                <BInputGroup id="conditionInput">
                                    <BInputGroupPrepend is-text><BIconChatDotsFill/></BInputGroupPrepend>
                                    <BFormInput v-model="quoteCondition"
                                                type="text"
                                                placeholder="예) 견적서 11월 15일까지 회신 부탁드립니다."
                                                />
                                </BInputGroup>
                            </BFormGroup>

                            <div class="text-right mt-3">
                                <BButton  variant="info" type="submit" 
                                :disabled="isSentUser || !validEmailState">입력완료</BButton>
                            </div>

                        </BForm>

                        <div slot="footer">
                            <div class="small text-muted">
                                <strong><i class="fa fa-copyright"></i> <a href='http://qqkorea.net'>(주)하우코스트</a></strong>
                                <span class="hidden-xs en"> All rights reserved.</span><br>
                                <span class="hidden-xs en">
                                    대표자 : 유현오 | E-Mail : <a href="mailto:estimate@howcost.co.kr">estimate@howcost.co.kr</a>) <br>
                                    <a href='http://xcost.me/bbs/qalist.php'>적산문의/프로그램/문의 1:1상담</a> | tel. 031-417-0466 <br/>
                                    사무실 : 경기도 수원시 권선구 수성로 8 경기중소기업성장지원센터 303호 <br/>부설연구소 : 건설계약연구소/벤쳐기업인증/연구개발서비스업등록/소프트웨어개발업/ISO9001,ISO14001인증<br/>
                                    사업자번호 : 809-86-00250</span>
                            </div>
                        </div>

                    </BCard>


                </BCol>
            </BRow>
        </BContainer>
    </div>
</template>

<script>
    import {
        apiCall,
        cloneVar,
        alertError,
        alertWarn,
        alertSuccess,
        alertConfirm,
        alertPageMove,
        warningAlertPageMove,
        checkBisNo,
        commify,
        toastSync,
        getMask,
        warpSite,
    } from '@/common/utils';

    export default {
        name: 'QuoteInfoInput',
        props: ['memberToken'],
        data () {
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            // 15th two months prior
            const minDate = new Date(today)
            minDate.setMonth(minDate.getMonth())
            minDate.setDate(now.getDate())
            
            return {
                companyName : '',
                userName : '',
                userPhone : '',
                userEmail: '',
                projectName: '',
                userNo  : '',
                userId  : '',
                dateValue: '',
                isSentUser: false,
                isValidUser: false,
                emailRegexp: /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                min: minDate,
                quoteData: {},
                quoteCondition: "",
            }
        },
        async created(){
            console.log("-------- created --------" );
            try{
                const userInfo = await this.infoImport();
                
                await this.getPhoneMask(userInfo.result.xcUser.telNo);
                this.companyName = userInfo.result.xcUser.companyName;
                this.userName = userInfo.result.xcUser.name;
                this.userEmail= userInfo.result.xcUser.email;
                this.projectName= userInfo.result.xcUser.projectName;
            }catch(err){
                console.log('ERROR: ' + err);
                await warningAlertPageMove( this.$bvModal, '비정상적인 접근입니다. 페이지 종료 후 다시 시도해주세요.', '오류' );
                return;
            }
        },
        beforeMount() {

        },
        mounted(){
            console.log("-------- mounted: MemberFind -----  " );
        },

        computed: {
            validEmailState(){
                if(!this.emailRegexp.test(this.userEmail)) {
                    return false;
                } else {
                    return true;
                }
            },

        },
        methods: {
            async onSubmit(e){
                e.preventDefault();

                if(this.dateValue.length < 1) {
                    return await alertWarn( this.$bvModal, '자재 요청일자를 선택하세요' );
                }

                try{
                    this.quoteDataFilter();

                    const r = await apiCall('post', `/api/quote/create-quote-preview`, this.quoteData);
                    // console.log( r );
                    if(r.code===200){
                        // console.log("r.result.xcUser", r.result.xcUser);
                        this.$router.push({ name: 'QuoteInfo', params: { data: r.result.xcUser } });
                    }
                }catch(err){
                    console.log( err );
                    await alertWarn( this.$bvModal, 'ERROR: ' + err.message, '에러' );
                }
            },

            async getPhoneMask(phoneNumber) {
                let res = getMask(phoneNumber);
                this.userPhone = res;
                // this.model.userPhone = this.userPhone.replace(/[^0-9]/g, '');
            },

            async infoImport() {
                this.userNo = this.$route.params.uno;
                this.userId = this.$route.query.userid;
                try{
                    const param = {
                        userNo : this.userNo,
                        userId : this.userId
                    };
                    const r = await apiCall('post', `/api/quote/base-info/${this.userNo}`, param);
                    if(r.code===200){
                        return r;
                    } else if (r.code === 400) {
                        await warningAlertPageMove( this.$bvModal, '비정상적인 접근입니다. 페이지 종료 후 다시 시도해주세요.', '오류' );
                    } else {
                        await warningAlertPageMove( this.$bvModal, r.message, '재시도 권고', warpSite() );
                    }
                }catch(err){
                    console.log( err );
                    await alertWarn( this.$bvModal, 'ERROR: ' + err.message, 'ERROR' );
                }
            },

            quoteDataFilter() {
                this.quoteData.userNo = this.userNo;
                this.quoteData.userId = this.userId;
                this.quoteData.reqDate = this.dateValue;
                this.quoteData.companyName = this.companyName;
                this.quoteData.userName = this.userName;
                this.quoteData.userPhone = this.userPhone;
                this.quoteData.userEmail = this.userEmail;
                this.quoteData.projectName = this.projectName;
                this.quoteData.quoteCondition = this.quoteCondition;
            },

        }
    }
</script>
